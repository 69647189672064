"use client";

import { MutableRefObject, useEffect, useMemo, useState } from "react";

export type GridNode = {
	above?: GridNode;
	below?: GridNode;
	left?: GridNode;
	right?: GridNode;

	element: HTMLDivElement;
};

export default function Grid({
	givenWidth,
	givenheight,
	squareSize = 40,

	gridNodes,
}: {
	givenWidth?: number;
	givenheight?: number;
	squareSize?: number;

	gridNodes: MutableRefObject<GridNode[][]>;
}) {
	const [width, setWidth] = useState(givenWidth);
	const [height, setHeight] = useState(givenheight);
	useEffect(
		() => {
			if (!width) setWidth(innerWidth);
			if (!height) setHeight(innerHeight);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const rows = useMemo(
			() => Math.ceil((height ?? 0) / squareSize),
			[height, squareSize]
		),
		cols = useMemo(
			() => Math.ceil((width ?? 0) / squareSize),
			[width, squareSize]
		);

	return (
		<div
			className="overflow-hidden"
			style={{
				width,
				height,
			}}
		>
			<div
				className="grid"
				style={{
					gridTemplateColumns: `repeat(${cols}, ${squareSize}px)`,
					gridTemplateRows: `repeat(${rows}, ${squareSize}px)`,
				}}
			>
				{Array.from({ length: rows * cols }).map((_, i) => {
					if (i === 0) gridNodes.current = []; // reset nodes on first render

					const x = i % cols,
						y = Math.floor(i / cols);

					if (!gridNodes.current[x]) gridNodes.current[x] = [];
					return (
						<div
							ref={(el) => {
								if (!el) return;

								const above = gridNodes.current[x]?.[y - 1],
									left = gridNodes.current[x - 1]?.[y];
								const thisNode = (gridNodes.current[x][y] = {
									above,
									left,

									element: el,
								});
								if (above) above.below = thisNode;
								if (left) left.right = thisNode;

								gridNodes.current[x][y] = thisNode;
							}}
							key={i}
							className="bg-opacity-0 transition-colors bg-white"
						/>
					);
				})}
			</div>
		</div>
	);
}
