"use client";

import Grid, { GridNode } from "@/components/proceduralGrid/grid";
import { animateMisc } from "@/utils/drawing";
import { useEffect, useRef } from "react";

const noDuplicateSplitFor = 4,
	perXDelay = 150;

export default function AnimatedGrid() {
	const nodes = useRef<GridNode[][]>([]);
	useEffect(() => {
		const interval = setInterval(() => {
			if (!nodes.current?.[0]?.length) return;

			const cols = nodes.current.length,
				rows = nodes.current[0].length;

			let x = 0,
				activeYs = new Map<
					number,
					{
						noDownSplitFor: number;
						noUpSplitFor: number;

						last?: GridNode;
					}
				>();
			activeYs.set(Math.round(Math.random() * rows), {
				noDownSplitFor: 0,
				noUpSplitFor: 0,
			});

			let lastX = -1;
			animateMisc((p) => {
				x = Math.round(p * cols);
				const pendingAdditions: typeof activeYs = new Map(),
					pendingRemovals = new Set<number>(),
					pendingSplitDecrement = new Set<number>();
				// only apply the animation step if it has progressed to a new x
				if (x !== lastX) {
					activeYs.forEach(
						({ noDownSplitFor, noUpSplitFor, last: lastElement }, y) => {
							const current = nodes.current[x]?.[y],
								currentElement = current?.element,
								absoluteLastElement = lastElement ?? nodes.current[x - 1]?.[y];
							if (!currentElement) return;

							const initialDelay = 300;
							currentElement.style.transitionDuration = initialDelay + "ms";
							currentElement.style.backgroundColor = `rgba(255, 255, 255, 0.1)`;
							setTimeout(() => {
								currentElement.style.transitionDuration = "2000ms";
								currentElement.style.backgroundColor = `rgba(255, 255, 255, 0)`;
							}, initialDelay);

							// if (absoluteLastElement) {
							// 	absoluteLastElement.element.style.transitionDuration = "3000ms";
							// 	absoluteLastElement.element.style.backgroundColor = `rgba(255, 255, 255, 0)`;
							// }

							if (
								(noDownSplitFor === 0 || noUpSplitFor === 0) &&
								Math.random() > 0.5
							) {
								if (noUpSplitFor === 0 && y + 1 < rows)
									pendingAdditions.set(y + 1, {
										noDownSplitFor: noDuplicateSplitFor,
										noUpSplitFor: 0,
										last: current,
									});
								if (noDownSplitFor === 0 && y - 1 >= 0)
									pendingAdditions.set(y - 1, {
										noDownSplitFor: 0,
										noUpSplitFor: noDuplicateSplitFor,
										last: current,
									});
								pendingRemovals.add(y);
							} else pendingSplitDecrement.add(y);
						}
					);

					pendingSplitDecrement.forEach((y) => {
						const current = activeYs.get(y);
						if (!current) return;
						activeYs.set(y, {
							noDownSplitFor: Math.max(current.noDownSplitFor - 1, 0),
							noUpSplitFor: Math.max(current.noUpSplitFor - 1, 0),
						});
					});
					pendingAdditions.forEach((v, y) => activeYs.set(y, v));
					pendingRemovals.forEach((y) => activeYs.delete(y));
				}
				lastX = x;
			}, perXDelay * cols);
		}, 1500);

		return () => clearInterval(interval);
	}, []);

	return <Grid gridNodes={nodes} />;
}
